import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PickupItem = ({ icon, header, caption, tint = 'success', children }) => (
  <Card style={{ backgroundColor: '#f7f7f9', height: '100%', border: '0' }}>
    <CardBody style={{ height: '25%' }}>
      <FontAwesomeIcon icon={icon} size="2x" />
    </CardBody>
    <CardBody style={{ height: '25%' }}>
      <h6>{header}</h6>
    </CardBody>
    <div style={{ height: '50%' }}>
      <CardBody className={`text-${tint}`}>{caption()}</CardBody>
      {children ? <CardBody>{children}</CardBody> : null}
    </div>
  </Card>
);

const Deliveries = ({ pickupAvailable }) => (
  <div>
    <Row className="text-center">
      <Col lg={6}>
        <PickupItem
          icon="store"
          header="FREE Store Pickup"
          tint={pickupAvailable ? 'success' : 'danger'}
          caption={() => (
            <span>
              {pickupAvailable ? '6 items available at' : 'Sold out at'}
              <br />
              New York 5th Ave
            </span>
          )}
        />
      </Col>
      <Col lg={6}>
        <PickupItem
          icon="truck"
          header="Delivery"
          caption={() => <span>Free Delivery available</span>}
        />
      </Col>
    </Row>
  </div>
);

export default Deliveries;
