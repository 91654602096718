import React from 'react';
import Success from '../components/CheckoutWizard/Success';
import ErrorDisplay from '../components/CheckoutWizard/ErrorDisplay';
import OrderComposer from '../components/CheckoutWizard/OrderComposer';
import CheckingOut from '../components/CheckoutWizard/CheckingOut';
import {
  checkoutErrorOccured,
  checkoutStarted
} from '../store/actions/checkout';
import { connect } from 'react-redux';
import { checkoutThunk } from '../store/thunks/checkout';

const canCheckout = ({ products, address, channel, payment, shipping }) => {
  let errors = [];

  if (products && products.length <= 0) {
    errors = [...errors, 'Not enough products in basket.'];
  }
  if (!address) {
    errors = [...errors, 'Address not complete.'];
  }
  if (!payment) {
    errors = [...errors, 'No payment method is selected.'];
  }
  if (!shipping) {
    errors = [...errors, 'No shipping option selected.'];
  }

  return errors;
};

const CheckoutWizard = ({
  errors,
  onErrors,
  checkingOut,
  onStart,
  success
}) => {
  const onSubmit = checkoutData => {
    const errors = canCheckout(checkoutData);
    if (errors.length) {
      return onErrors(errors);
    }
    onStart(checkoutData);
  };

  return (
    <div>
      {checkingOut && <CheckingOut />}
      {success && <Success />}
      {errors && <ErrorDisplay errors={errors} />}
      {!checkingOut && !success && <OrderComposer onSubmit={onSubmit} />}
    </div>
  );
};

const mapState = state => state.checkout.state;

const mapDispatch = dispatch => ({
  onErrors: e => dispatch(checkoutErrorOccured(e)),
  onStart: checkoutData => {
    dispatch(checkoutStarted());
    dispatch(checkoutThunk(checkoutData));
  }
});

export default connect(
  mapState,
  mapDispatch
)(CheckoutWizard);
