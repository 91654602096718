import React from 'react';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const ProductionWall = ({ match }) => (
  <Row style={{ marginTop: 30 }}>
    <Col lg={{ size: 10, offset: 1 }} className="jumbotron text-center">
      <div className="">
        <h1>You tried to access production!</h1>
        <Row>
          <Col lg={12}>
            This tool is not designed to access the production system. If you
            need to change any production data please talk to an appropriate
            engineering representative.
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            You can switch to a different environment using one of the following
            links:
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col lg={{ size: 6, offset: 3 }}>
            <ListGroup>
              <Link to={`/d/${match.params.tenant}`}>
                <ListGroupItem>Demo</ListGroupItem>
              </Link>
              <Link to={`/x/${match.params.tenant}`}>
                <ListGroupItem>Sandbox</ListGroupItem>
              </Link>
              <Link to={`/s/${match.params.tenant}`}>
                <ListGroupItem>Staging</ListGroupItem>
              </Link>
            </ListGroup>
          </Col>
        </Row>
      </div>
    </Col>
  </Row>
);

export default ProductionWall;
