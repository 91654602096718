import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

class ImageCarousel extends React.Component {
  /*
  the component has to keep internal state so that the carousel does not
  reset if the parent component gets re-rendered
   */
  state = { selected: 0 };

  render() {
    const { images } = this.props;
    const { selected } = this.state;
    return (
      <Carousel
        infiniteLoop={true}
        selectedItem={selected}
        onChange={selected => this.setState({ selected })}
      >
        {images.map(image => (
          <div key={image.url}>
            <img src={image.url} alt={image.alt_text} />
          </div>
        ))}
      </Carousel>
    );
  }
}

export default ImageCarousel;
