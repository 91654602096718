export default {
  shop: 'storefront-catalog-en',
  locale: 'en-US',
  initialProducts: ['W400453-WTS-SCR-3'],
  initialAddress: {
    salutation: 'Mrs.',
    first_name: 'Emily',
    last_name: 'Parker',
    address_line_1: '55 Thompson Street',
    state: 'NY',
    zip_code: '10012',
    city: 'New York',
    country_code: 'US',
    email: 'parker_emily@icloud.com'
  },
  platformTenant: 'marketing-dodici',
  brand: 'Outdoor Voices'
};
