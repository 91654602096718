import tenants from '../../../tenants';
import { ENVIRONMENT_SELECTED } from '../../actions/global';

const configuredCombineReducers = reducers => (
  state = {},
  action,
  config = null
) => {
  // need to do that so that when the ENVIRONMENT_SELECTED action happens
  // we have the config already injected into all reducers
  const tenant =
    action.type === ENVIRONMENT_SELECTED ? action.payload.tenant : state.tenant;

  return Object.keys(reducers).reduce(
    (nextState, key) => ({
      ...nextState,
      [key]: reducers[key](state[key], action, config || tenants[tenant] || {})
    }),
    {}
  );
};

export default configuredCombineReducers;
