import React from 'react';
import { Button, Col, Row } from 'reactstrap';

const SubmitButton = ({ style = {}, disabled }) => (
  <div style={{ marginTop: 10, ...style }}>
    <Row>
      <Col xs="12" sm="12" lg="12" className="text-right">
        <Button type="submit" color="info" disabled={disabled}>
          Submit order
        </Button>
      </Col>
    </Row>
  </div>
);

export default SubmitButton;
