import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { loadProductById } from '../store/thunks/products';
import Loading from '../components/Loading';
import ImageCarousel from '../components/ProductDetail/ImageCarousel';
import ProductDetailTitle from '../components/ProductDetail/ProductDetailTitle';
import ProductInspection from '../components/ProductDetail/ProductInspection';

class ProductDetail extends React.Component {
  state = {
    size: null
  };

  getCurrentProductId = () => this.props.match.params.id;

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad(this.getCurrentProductId());
  }

  render() {
    const { products } = this.props;
    const { size } = this.state;

    const product = products[this.getCurrentProductId()];

    if (!product || product.loading) {
      return (
        <Loading message={`Loading product ${this.getCurrentProductId()}`} />
      );
    }

    return (
      <div>
        <ProductDetailTitle product={product} />
        <Row style={{ marginTop: 30 }}>
          <Col lg={6}>
            <div>
              <ImageCarousel images={product.images} />
            </div>
          </Col>
          <Col lg={6}>
            <ProductInspection
              product={product}
              onSizeSelected={size => this.setState({ size })}
              selectedSize={size || product.variation_size_value}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = state => ({ products: state.products });

const mapDispatch = dispatch => ({
  onLoad: id => dispatch(loadProductById(id))
});

export default connect(
  mapState,
  mapDispatch
)(ProductDetail);
