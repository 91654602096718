import dodici from './dodici';

export default {
  ...dodici,
  initialProducts: ['574sport_10'],
  initialAddress: {
    salutation: 'Mr.',
    first_name: 'Michael',
    last_name: 'Roorda',
    address_line_1: '60 South St',
    state: 'MA',
    zip_code: '02111',
    city: 'Boston',
    country_code: 'US',
    email: 'michael.roorda@newstore.com'
  },
  platformTenant: 'marketing-dodici',
  brand: 'New Balance'
};
