import React from 'react';
import BorderlessListGroupItem from '../BorderlessListGroupItem';
import { Button, Table, ListGroup } from 'reactstrap';
import SizeSelect from './SizeSelect';
import Deliveries from './Deliveries';
import {connect} from "react-redux";

const EXTENDED_ATTR_TO_SHOW = ['fit', 'care'];
const EXTENDED_ATTR_TRANSLATIONS = { fit: 'Fit', care: 'Care' };
const toExtendedAttributeComponent = ea =>
  EXTENDED_ATTR_TO_SHOW.find(e => e === ea.name) ? (
    <tr key={ea.name}>
      <td>{EXTENDED_ATTR_TRANSLATIONS[ea.name]}</td>
      <td>{ea.value}</td>
    </tr>
  ) : null;

const ProductInspection = ({ product, selectedSize, onSizeSelected, tenant, environment }) => (
  <ListGroup flush>
    <BorderlessListGroupItem>
      <h2 style={{ fontWeight: 1200 }}>${product.price}</h2>
    </BorderlessListGroupItem>
    <BorderlessListGroupItem>{product.caption}</BorderlessListGroupItem>
    <BorderlessListGroupItem>
      <Table>
        <tbody>
          <tr>
            <td>Color</td>
            <td>{product.variation_color_value}</td>
          </tr>
          {product.extended_attributes.map(toExtendedAttributeComponent)}
        </tbody>
      </Table>
    </BorderlessListGroupItem>
    <BorderlessListGroupItem>
      <SizeSelect
        sizes={product.variation_attributes.variation_size_values}
        onSelected={s => onSizeSelected(s)}
        selected={selectedSize}
      />
    </BorderlessListGroupItem>
    <BorderlessListGroupItem>
      <Button color="info" block>
        Add to bag
      </Button>
    </BorderlessListGroupItem>
    <BorderlessListGroupItem>
      <Button color="info" href={`/#/${environment}/${tenant}/checkoutwizard`} tag="a" block>
        Checkout
      </Button>
    </BorderlessListGroupItem>
    <BorderlessListGroupItem>
      <Deliveries
        pickupAvailable={selectedSize === product.variation_size_value}
      />
    </BorderlessListGroupItem>
    <BorderlessListGroupItem>
      <Button outline color="primary" size="sm" block>
        Change Store
      </Button>
    </BorderlessListGroupItem>
  </ListGroup>
);

const mapState = state => ({
    tenant: state.tenant,
    environment: state.environment,
});

export default connect(mapState)(ProductInspection);
