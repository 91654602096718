import { CHECKOUT_ADDRESS_CHANGED } from '../../actions/checkout';

export default (state = null, action, config) => {
  switch (action.type) {
    case CHECKOUT_ADDRESS_CHANGED:
      return { ...action.payload };
    default:
      return state || config.initialAddress;
  }
};
