import { createAction } from './_shared';

export const CHECKOUT_ERROR_OCCURED = 'CHECKOUT_ERROR_OCCURED';
export const checkoutErrorOccured = e =>
  createAction(CHECKOUT_ERROR_OCCURED, Array.isArray(e) ? e : [e]);

export const CHECKOUT_STARTED = 'CHECKOUT_STARTED';
export const checkoutStarted = () => createAction(CHECKOUT_STARTED);

export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const checkoutSuccess = () => createAction(CHECKOUT_SUCCESS);

export const CHECKOUT_PAYMENT_SELECTED = 'CHECKOUT_PAYMENT_SELECTED';
export const checkoutPaymentSelected = p =>
  createAction(CHECKOUT_PAYMENT_SELECTED, p);

export const CHECKOUT_SHIPPING_SELECTED = 'CHECKOUT_SHIPPING_SELECTED';
export const checkoutShippingSelected = s =>
  createAction(CHECKOUT_SHIPPING_SELECTED, s);

export const CHECKOUT_ADDRESS_CHANGED = 'CHECKOUT_ADDRESS_CHANGED';
export const checkoutAddressChanged = a =>
  createAction(CHECKOUT_ADDRESS_CHANGED, a);

export const SHIPPING_OPTIONS_LOADED = 'SHIPPING_OPTIONS_LOADED';
export const shippingOptionsLoaded = s =>
  createAction(SHIPPING_OPTIONS_LOADED, s);

export const SHIPPING_OPTIONS_LOADING_ERROR = 'SHIPPING_OPTIONS_LOADING_ERROR';
export const shippingOptionsLoadingError = e =>
  createAction(SHIPPING_OPTIONS_LOADING_ERROR, e);
