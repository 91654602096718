import React from 'react';
import { Alert } from 'reactstrap';

const Loading = ({ message }) => (
  <Alert color="info" className="text-center" style={{ marginTop: 30 }}>
    <h4 style={{ margin: 30 }}>
      {message}
      <br />
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </h4>
  </Alert>
);

export default Loading;
