import {
  PRODUCT_LOADED,
  PRODUCT_LOADING,
  PRODUCT_LOADING_ERROR
} from '../actions/products';

export default (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_LOADING:
      return { ...state, [action.payload.id]: { loading: true } };
    case PRODUCT_LOADED:
      return {
        ...state,
        [action.payload.id]: {
          ...action.payload.product
        }
      };
    case PRODUCT_LOADING_ERROR:
      return { ...state, [action.payload.id]: action.payload };
    default:
      return state;
  }
};
