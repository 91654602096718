import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import Tile from './Tile';

const uppercaseFirst = s => s.charAt(0).toUpperCase() + s.slice(1);
const AddressContext = React.createContext({});

const InputOnSteroids = ({ name, title = null, disabled = false }) => {
  return (
    <AddressContext.Consumer>
      {({ address, onChange }) => (
        <FormGroup>
          <Label for={name}>{title || uppercaseFirst(name)}</Label>
          <Input
            type="text"
            name={name}
            value={address[name]}
            onChange={e => {
              const newProp = {};
              newProp[name] = e.target.value;
              onChange({ ...address, ...newProp });
            }}
            disabled={disabled}
            required={true}
          />
        </FormGroup>
      )}
    </AddressContext.Consumer>
  );
};

const AddressEntry = ({ address, onChange }) => (
  <Tile title="Address">
    <AddressContext.Provider value={{ address, onChange }}>
      <InputOnSteroids name="salutation" />
      <InputOnSteroids name="first_name" title="First name" />
      <InputOnSteroids name="last_name" title="Last name" />
      <InputOnSteroids name="address_line_1" title="Address Line 1" />
      <InputOnSteroids name="state" />
      <InputOnSteroids name="zip_code" title="ZIP" />
      <InputOnSteroids name="city" />
      <InputOnSteroids
        name="country_code"
        title="Country Code"
        disabled={true}
      />
      <InputOnSteroids name="email" />
    </AddressContext.Provider>
  </Tile>
);

export default AddressEntry;
