import { createAction } from './_shared';

export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const productLoading = id => createAction(PRODUCT_LOADING, { id });

export const PRODUCT_LOADED = 'PRODUCT_LOADED';
export const productLoaded = (id, product) =>
  createAction(PRODUCT_LOADED, { id, product });

export const PRODUCT_LOADING_ERROR = 'PRODUCT_LOADING_ERROR';
export const productLoadingError = (id, error) =>
  createAction(PRODUCT_LOADING_ERROR, { id, error });
