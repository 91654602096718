import { DEFAULT_QUERY, getApiUrl } from './shared';
import {
  productLoaded,
  productLoading,
  productLoadingError
} from '../actions/products';

export const loadProductById = id => (dispatch, getState) => {
  if (!getState().products[id]) {
    dispatch(productLoading(id));
    fetch(`${getApiUrl(getState())}/products/${id}?${DEFAULT_QUERY}`)
      .then(r => r.json())
      .then(r => dispatch(productLoaded(id, r)))
      .catch(e => dispatch(productLoadingError(id, e)));
  }
};
