import configuredCombineReducers from '../shared/configuredCombineReducers';
import state from './state';
import payment from './payment';
import shipping from './shipping';
import address from './address';
import products from './products';

export default configuredCombineReducers({
  state,
  payment,
  shipping,
  address,
  products
});
