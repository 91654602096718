import React from 'react';
import Tile from './Tile';
import { Media, Table } from 'reactstrap';
import { loadProductById } from '../../store/thunks/products';
import { connect } from 'react-redux';

class ProductSelector extends React.Component {
  componentDidMount() {
    const { onLoad, productIds } = this.props;
    onLoad(productIds);
  }

  render() {
    const { productIds, products } = this.props;

    const productsToRender = productIds.map(pid => products[pid]);
    const loading =
      productsToRender.filter(p => !p).length +
        productsToRender.filter(p => p && p.loading).length >
      0;

    if (loading) {
      return <div>Loading products, please wait...</div>;
    }

    return (
      <Tile title="Products">
        {productsToRender.map(p => (
          <Media key={p.product_id}>
            <Media left>
              <Media
                src={p.images[0].url}
                style={{ width: 'auto', height: 170 }}
              />
            </Media>
            <Media body style={{ marginLeft: 10 }}>
              <Media heading>{p.title}</Media>
              <Media bottom>
                <Table>
                  <tbody>
                    <tr>
                      <td>Price</td>
                      <td>
                        {p.currency_code} {p.price}
                      </td>
                    </tr>
                    <tr>
                      <td>Size</td>
                      <td>{p.variation_size_value}</td>
                    </tr>
                    <tr>
                      <td>Color</td>
                      <td>{p.variation_color_value}</td>
                    </tr>
                  </tbody>
                </Table>
              </Media>
            </Media>
          </Media>
        ))}
      </Tile>
    );
  }
}

const mapState = state => ({ products: state.products });

const mapDispatch = dispatch => ({
  onLoad: products => products.forEach(p => dispatch(loadProductById(p)))
});

export default connect(
  mapState,
  mapDispatch
)(ProductSelector);
