import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { push } from 'connected-react-router';
import Col from 'reactstrap/es/Col';
import tenants from '../tenants';

class EnvironmentSelector extends React.Component {
  state = {
    tenant: '',
    environment: ''
  };

  componentDidMount() {
    if (this.state.tenant === '' || this.state.environment === '') {
      const { tenant, environment } = this.props;
      console.log(tenant, environment);
      this.setState({ tenant, environment });
    }
  }

  onSubmit = e => {
    e.preventDefault();
    const { tenant, environment } = this.state;
    this.props.onEnvironmentSelected(tenant, environment);
  };

  render() {
    const { tenant, environment } = this.state;

    return (
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <div className="text-center">
            <h3>Change environment</h3>
          </div>
          <Form onSubmit={this.onSubmit}>
            <FormGroup>
              <Label for="tenant">Tenant</Label>
              <Input
                name="tenant"
                type="select"
                value={tenant}
                onChange={e => this.setState({ tenant: e.target.value })}
              >
                {Object.keys(tenants).map(tenant => (
                  <option key={tenant} value={tenant}>
                    {tenants[tenant].brand}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="environment">Environment</Label>
              <Input
                name="environment"
                type="select"
                value={environment}
                onChange={e => this.setState({ environment: e.target.value })}
              >
                <option value="d">Demo</option>
                <option value="x">Sandbox</option>
                <option value="s">Staging</option>
              </Input>
            </FormGroup>
            <Button color="success">Change</Button>
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapState = state => ({
  tenant: state.tenant || '',
  environment: state.environment || ''
});
const mapDispatch = dispatch => ({
  onEnvironmentSelected: (t, e) => dispatch(push(`/${e}/${t}`))
});

export default connect(
  mapState,
  mapDispatch
)(EnvironmentSelector);
