import React from 'react';
import { Col, Form, Row } from 'reactstrap';
import SubmitButton from './SubmitButton';
import PaymentSelector from './PaymentSelector';
import ProductSelector from './ProductSelector';
import AddressEntry from './AddressEntry';
import ShippingOptions from './ShippingOptions';
import { connect } from 'react-redux';
import {
  checkoutAddressChanged,
  checkoutPaymentSelected,
  checkoutShippingSelected
} from '../../store/actions/checkout';

const decorateAddress = address => ({ phone: '6171231234', ...address });

const OrderComposer = ({
  products,
  address,
  payment,
  shipping,
  onPaymentSelected,
  onShippingSelected,
  onAddressChanged,
  onSubmit
}) => {
  const _onSubmit = e => {
    e.preventDefault();
    onSubmit({ products, address: decorateAddress(address), payment, shipping });
  };

  return (
    <Form onSubmit={_onSubmit}>
      <SubmitButton disabled={!shipping} />
      <Row>
        <Col lg="6">
          <ProductSelector productIds={products} />
          <AddressEntry address={address} onChange={onAddressChanged} />
        </Col>
        <Col lg="6">
          <ShippingOptions
            products={products}
            address={address}
            shipping={shipping}
            onChange={onShippingSelected}
          />
          <PaymentSelector payment={payment} onChange={onPaymentSelected} />
        </Col>
      </Row>
      <SubmitButton style={{ marginBottom: 10 }} disabled={!shipping} />
    </Form>
  );
};

const mapState = state => ({
  payment: state.checkout.payment.identifier,
  shipping: state.checkout.shipping.identifier,
  address: state.checkout.address,
  products: state.checkout.products
});

const mapDispatch = dispatch => ({
  onPaymentSelected: p => dispatch(checkoutPaymentSelected(p)),
  onShippingSelected: s => dispatch(checkoutShippingSelected(s)),
  onAddressChanged: a => dispatch(checkoutAddressChanged(a))
});

export default connect(
  mapState,
  mapDispatch
)(OrderComposer);
