import React from 'react';
import debounce from 'debounce';
import hash from 'object-hash';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import Tile from './Tile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shippingOptionsThunk } from '../../store/thunks/checkout';
import { connect } from 'react-redux';

class ShippingOptions extends React.Component {
  state = {
    prevHash: null
  };

  _reload = () => {
    const { products, address, onReload } = this.props;

    const propsHash = hash({ address, products });

    if (this.state.prevHash !== propsHash) {
      this.props.onChange('');
      this.setState({ prevHash: propsHash }, () =>
        onReload({
          items: products.map(product_id => ({ product_id, quantity: 1 })),
          partial_address: address
        })
      );
    }
  };
  reloadOptions = debounce(this._reload, 1000);

  onSelected = (e, selected) => {
    e.preventDefault();
    this.props.onChange(selected);
  };

  getIcon = name =>
    name.startsWith('Collect')
      ? 'store'
      : name.startsWith('On-Demand')
      ? 'dolly'
      : 'truck';

  componentDidMount() {
    this.reloadOptions();
  }

  componentDidUpdate() {
    this.reloadOptions();

    const { shipping, options, onChange } = this.props;

    if (options && !shipping) {
      onChange(options[0].identifier);
    }
  }

  render() {
    const { shipping, error, options } = this.props;

    const loading = (!options || !options.length) && !error;

    return (
      <Tile title="Shipping option">
        {loading && <span>Loading shipping options...</span>}
        {error && <span>Error loading shipping options!</span>}
        {options && (
          <ListGroup>
            {options.map(o => (
              <ListGroupItem
                tag="button"
                action
                key={o.identifier}
                active={o.identifier === shipping}
                onClick={e => this.onSelected(e, o.identifier)}
              >
                <Row>
                  <Col xs="1">
                    <FontAwesomeIcon icon={this.getIcon(o.display_name)} />
                  </Col>
                  <Col xs="11">
                    <b>{o.display_name}</b>{' '}
                    <small className="text-muted">{o.delivery_time}</small>
                  </Col>
                </Row>
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
      </Tile>
    );
  }
}

const mapState = state => state.checkout.shipping;

const mapDispatch = dispatch => ({
  onReload: p => dispatch(shippingOptionsThunk(p))
});

export default connect(
  mapState,
  mapDispatch
)(ShippingOptions);
