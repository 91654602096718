import {
  CHECKOUT_SHIPPING_SELECTED,
  SHIPPING_OPTIONS_LOADED,
  SHIPPING_OPTIONS_LOADING_ERROR
} from '../../actions/checkout';

const defaultState = {
  identifier: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SHIPPING_OPTIONS_LOADED:
      return { options: action.payload };
    case SHIPPING_OPTIONS_LOADING_ERROR:
      return { error: action.payload };
    case CHECKOUT_SHIPPING_SELECTED:
      return { ...state, identifier: action.payload };
    default:
      return state;
  }
};
