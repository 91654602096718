import React from 'react';
import { Redirect, Route } from 'react-router';
import CheckoutWizard from '../screens/CheckoutWizard';
import ProductionWall from './ProductionWall';
import { connect } from 'react-redux';
import { environmentSelected } from '../store/actions/global';
import EnvironmentSelector from '../screens/EnvironmentSelector';
import ProductDetail from '../screens/ProductDetail';

class Routes extends React.Component {
  componentWillMount() {
    this.refreshEnv();
  }

  componentDidUpdate() {
    this.refreshEnv();
  }

  refreshEnv = () => {
    const {
      match,
      onEnvironmentSelected,
      currentTenant,
      currentEnvironment
    } = this.props;
    const { tenant, env } = match.params;

    if (currentTenant !== tenant || currentEnvironment !== env) {
      onEnvironmentSelected(tenant, env);
    }
  };

  render() {
    const { match, currentTenant, currentEnvironment } = this.props;
    const { env } = match.params;

    if (!currentTenant || !currentEnvironment) {
      return (
        <div className="text-center">Please wait, preparing environment</div>
      );
    }

    if (env === 'p') {
      return <ProductionWall match={match} />;
    }

    return (
      <div style={{ marginTop: 30 }}>
        <Route
          path={match.path}
          exact
          component={() => <Redirect to={`${match.url}/checkoutwizard`} />}
        />
        <Route
          path={`${match.path}/envselect`}
          exact
          component={EnvironmentSelector}
        />
        <Route
          path={`${match.path}/checkoutwizard`}
          component={CheckoutWizard}
        />
        <Route
          path={`${match.path}/pdp/:id`}
          component={({ match }) => (
            <ProductDetail match={match} key={match.params.id} />
          )}
        />
      </div>
    );
  }
}

const mapState = state => ({
  currentTenant: state.tenant,
  currentEnvironment: state.environment
});

const mapDispatch = dispatch => ({
  onEnvironmentSelected: (tenant, env) =>
    dispatch(environmentSelected(tenant, env))
});

export default connect(
  mapState,
  mapDispatch
)(Routes);
