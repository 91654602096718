import { CHECKOUT_PAYMENT_SELECTED } from '../../actions/checkout';

const defaultState = {
  identifier: 'tok_visa'
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CHECKOUT_PAYMENT_SELECTED:
      return { identifier: action.payload };
    default:
      return state;
  }
};
