import reducers from './reducers';
import { connectRouter } from 'connected-react-router';
import { ENVIRONMENT_SELECTED } from './actions/global';
import configuredCombineReducers from './reducers/shared/configuredCombineReducers';

export default history => {
  const appReducer = configuredCombineReducers({
    ...reducers,
    router: connectRouter(history)
  });

  return (state, action) => {
    if (action.type === ENVIRONMENT_SELECTED) {
      state = { router: state.router };
    }

    return appReducer(state, action);
  };
};
