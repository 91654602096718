import React from 'react';
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from 'reactstrap';
import { connect } from 'react-redux';

const NAV_HIDDEN_LOCALSTORAGE_KEY = 'nav_hidden';

const translateEnv = e =>
  e === 'd' ? 'Demo' : e === 'x' ? 'sandbox' : e === 's' ? 'staging' : e;

class Navigation extends React.Component {
  state = {
    isOpen: false,
    visible: localStorage.getItem(NAV_HIDDEN_LOCALSTORAGE_KEY) === null
  };

  toggleCollapse = () => this.setState({ isOpen: !this.state.isOpen });
  toggleVisibility = () =>
    this.setState({ visible: !this.state.visible }, () => {
      if (this.state.visible) {
        localStorage.removeItem(NAV_HIDDEN_LOCALSTORAGE_KEY);
      } else {
        localStorage.setItem(NAV_HIDDEN_LOCALSTORAGE_KEY, 'true');
      }
    });

  render() {
    const { tenant, environment, brand, products } = this.props;

    const productId = !!products ? products[0] : '';

    const { visible } = this.state;
    return (
      <Navbar color={'dark'} dark expand="md">
        <Container>
          <NavbarBrand href="/" className="navbar-custom-brand">
            {brand}
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleCollapse} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {visible && [
                <NavItem key="pdp">
                  <NavLink
                    href={`/#/${environment}/${tenant}/pdp/${productId}`}
                  >
                    PDP
                  </NavLink>
                </NavItem>,
                <NavItem key={'checkout'}>
                  <NavLink href={`/#/${environment}/${tenant}/checkoutwizard`}>
                    Checkout
                  </NavLink>
                </NavItem>,
                <NavItem key="env">
                  <NavLink href={`/#/${environment}/${tenant}/envselect`}>
                    Current environment: {tenant} @ {translateEnv(environment)}
                  </NavLink>
                </NavItem>
              ]}
              <NavItem>
                <NavLink onClick={this.toggleVisibility}>
                  {visible ? 'Hide' : 'Nav'}
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  tenant: state.tenant,
  environment: state.environment,
  brand: state.config.brand,
  products: state.config.initialProducts
});

export default connect(mapStateToProps)(Navigation);
