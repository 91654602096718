import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

const Tile = ({ title, children }) => (
  <Row style={{ marginTop: 10 }}>
    <Col lg={12}>
      <Card className="shadow">
        <CardHeader tag="h4">{title}</CardHeader>
        <CardBody>{children}</CardBody>
      </Card>
    </Col>
  </Row>
);

export default Tile;
