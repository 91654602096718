import React from 'react';
import { ListGroupItem } from 'reactstrap';

const BorderlessListGroupItem = props => (
  <ListGroupItem {...props} style={{ border: 0 }}>
    {props.children}
  </ListGroupItem>
);

export default BorderlessListGroupItem;
