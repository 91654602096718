import React from 'react';
import { Alert } from 'reactstrap';

const ErrorDisplay = ({ errors }) => (
  <Alert color="danger">
    There were errors submitting the order:
    <ul>
      {errors.map(e => (
        <li key={e || 1}>{e}</li>
      ))}
    </ul>
  </Alert>
);

export default ErrorDisplay;
