import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';

const SizeSelect = ({ sizes, selected, onSelected }) => (
  <div>
    <h6>Size</h6>
    <ListGroup style={{ marginTop: 15 }}>
      {sizes.map(size => (
        <ListGroupItem
          active={size === selected}
          tag="button"
          action
          key={size}
          onClick={() => onSelected(size)}
        >
          {size}
        </ListGroupItem>
      ))}
    </ListGroup>
  </div>
);

export default SizeSelect;
