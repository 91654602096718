import {
  checkoutErrorOccured,
  checkoutSuccess,
  shippingOptionsLoaded,
  shippingOptionsLoadingError
} from '../actions/checkout';
import uuid from 'uuid';
import { DEFAULT_QUERY, getApiUrl } from './shared';

export const checkoutThunk = ({ products, address, payment, shipping }) => (
  dispatch,
  getState
) =>
  fetch(`${getApiUrl(getState())}/checkout/submit?${DEFAULT_QUERY}`, {
    method: 'POST',
    body: JSON.stringify({
      channel: 'web',
      channel_type: 'web',
      shipping_identifier: shipping,
      shipping_address: address,
      billing_address: address,
      consumer_id: uuid.v4(),
      consumer_language: 'en',
      currency: 'USD',

      consumer_name: `${address.first_name} ${address.last_name}`,
      consumer_email: address.email,
      payment: {
        psp: 'stripe',
        token: payment,
        method_code: 'credit_card'
      },
      items: products.map(p => ({ product_id: p, quantity: 1 }))
    })
  })
    .then(r =>
      dispatch(
        r.status < 400 ? checkoutSuccess() : checkoutErrorOccured('Error')
      )
    )
    .catch(e => dispatch(checkoutErrorOccured(e)));

export const shippingOptionsThunk = ({ items, partial_address }) => (
  dispatch,
  getState
) =>
  fetch(`${getApiUrl(getState())}/checkout/shipping_options?${DEFAULT_QUERY}`, {
    method: 'POST',
    body: JSON.stringify({ items, partial_address, currency: 'USD' })
  })
    .then(r => {
      if (r.status < 400) return r.json();
      else throw new Error();
    })
    .then(r => dispatch(shippingOptionsLoaded(r.items)))
    .catch(e => dispatch(shippingOptionsLoadingError(e)));
