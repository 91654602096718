import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './theme.scss';
import './index.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faCcDinersClub,
  faCcJcb
} from '@fortawesome/free-brands-svg-icons';
import { faTruck, faStore, faDolly } from '@fortawesome/free-solid-svg-icons';

library.add(
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faCcDinersClub,
  faCcJcb,
  faTruck,
  faStore,
  faDolly
);

ReactDOM.render(<App />, document.getElementById('root'));
