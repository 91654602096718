import React from 'react';
import { Container } from 'reactstrap';
import Routes from './components/Routes';
import { createHashHistory } from 'history';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { DEFAULT_ENV, DEFAULT_TENANT, VERSION } from './constants';
import { applyMiddleware, compose, createStore } from 'redux';
import reducer from './store/reducer';
import logger from 'redux-logger';
import { Provider } from 'react-redux';
import Navigation from './components/Navigation';
import thunk from 'redux-thunk';

const history = createHashHistory();

const store = createStore(
  reducer(history),
  {},
  compose(applyMiddleware(routerMiddleware(history), thunk, logger))
);

const App = () => {
  return (
    <Provider store={store}>
      <Navigation />
      <Container>
        <ConnectedRouter history={history}>
          <div>
            <Route
              path="/"
              exact
              component={() => <Redirect to={`/${DEFAULT_ENV}`} />}
            />
            <Route
              path="/:env"
              exact
              component={() => (
                <Redirect to={`/${DEFAULT_ENV}/${DEFAULT_TENANT}`} />
              )}
            />
            <Route path="/:env/:tenant" component={Routes} />
          </div>
        </ConnectedRouter>
      </Container>
      <div className="text-center text-muted">
        <small>Running version: {VERSION}</small>
      </div>
    </Provider>
  );
};

export default App;
