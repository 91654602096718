import React from 'react';
import { Alert, Button } from 'reactstrap';

const Success = () => (
  <Alert color="success" className="text-center" style={{ marginTop: 30 }}>
    <h4 style={{ margin: 30 }}>Thank you for your order</h4>
    <div style={{ marginBottom: 30 }}>
      <Button onClick={() => window.location.reload(true)} color="primary">
        Home
      </Button>
    </div>
  </Alert>
);

export default Success;
