import React from 'react';
import { Col, Row } from 'reactstrap';

const ProductDetailTitle = ({ product }) => (
  <div>
    <Row>
      <Col lg={12}>
        {product.main_category_full_path}
        <hr />
      </Col>
    </Row>
    <Row>
      <Col lg={12}>
        <div>
          <h4>{product.title}</h4>
        </div>
        <div>
          <small>SKU #{product.external_identifiers.sku}</small>
        </div>
      </Col>
    </Row>
  </div>
);

export default ProductDetailTitle;
