import React from 'react';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import Tile from './Tile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PAYMENT_TYPES = {
  tok_visa: {
    name: 'Visa',
    icon: ['fab', 'cc-visa']
  },
  tok_amex: {
    name: 'American Express',
    icon: ['fab', 'cc-amex']
  }
};

const PaymentSelector = ({ payment, onChange }) => (
  <Tile title="Payment Method">
    <ListGroup>
      {Object.keys(PAYMENT_TYPES).map(k => (
        <ListGroupItem
          tag="button"
          action
          key={k}
          active={k === payment}
          onClick={e => {
            e.preventDefault();
            onChange(k);
          }}
        >
          <Row>
            <Col xs={1} className="text-center">
              <FontAwesomeIcon
                icon={PAYMENT_TYPES[k].icon}
                mask="fab"
                size="lg"
              />
            </Col>
            <Col xs={11}>
              <span>{PAYMENT_TYPES[k].name}</span>
            </Col>
          </Row>
        </ListGroupItem>
      ))}
    </ListGroup>
  </Tile>
);

export default PaymentSelector;
