import tenant from './tenant';
import environment from './environment';
import checkout from './checkout';
import products from './products';
import platformTenant from './platformTenant';
import config from './config';

export default {
  tenant,
  environment,
  checkout,
  products,
  platformTenant,
  config
};
