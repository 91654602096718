import {
  CHECKOUT_ERROR_OCCURED,
  CHECKOUT_STARTED,
  CHECKOUT_SUCCESS
} from '../../actions/checkout';

export default (state = {}, action) => {
  switch (action.type) {
    case CHECKOUT_ERROR_OCCURED:
      return {
        ...state,
        errors: action.payload,
        checkingOut: false,
        success: false
      };
    case CHECKOUT_SUCCESS:
      return { ...state, errors: null, checkingOut: false, success: true };
    case CHECKOUT_STARTED:
      return { ...state, errors: null, checkingOut: true, success: false };
    default:
      return state;
  }
};
